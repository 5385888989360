<template>
    <div class="home">
        <div class="beijing">
            <swiper :options="swiperOptionOne" class="swiper-wrap">
                <swiper-slide v-for="(x, i) in carousel" :key="i">
                    <el-image :src="x"> </el-image>
                </swiper-slide>
            </swiper>
            <div class="loginBox">
                <div class="herderBox">立即注册 免费试用</div>
                <el-form :model="formData" status-icon :rules="rules" ref="formData" label-width="0px" class="demo-ruleForm">
                    <el-form-item label="" prop="name">
                        <el-input type="input" v-model.trim="formData.name" placeholder="请输入您的名字" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="phone">
                        <el-input type="input" v-model.trim="formData.phone" placeholder="请输入您的手机号" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="code" class="code">
                        <el-input v-model="formData.code" placeholder="请输入4位验证码"></el-input>
                        <el-button type="primary" @click="obtain(formData.phone)" v-if="show">获取验证码</el-button>
                        <el-button type="primary" :disabled="true" v-if="!show">重新发送({{ count }})</el-button>
                    </el-form-item>
                    <el-form-item>
                        <div class="submitForm" @click="submitForm">立即注册</div>
                        <div class="toSystem">注册成功 <a href="https://test.fzygsz.com/" target="_blank">立即前往</a></div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div>
            <div class="product_features">
                <div class="features_title">产品特色</div>
                <div class="features_subtitle">
                    <span class="subtitle">PRODUCT FEATURES</span>
                </div>
                <div class="list">
                    <div class="list_item" @mouseenter="enterfeature1" @mouseleave="outfeature1">
                        <div class="container" v-if="!featureActive1">
                            <img src="@/assets/image/icon/feature1_2.png" class="img2" />
                            <p class="space"></p>
                            <p class="serial">01</p>
                            <p class="simple">功能实用</p>
                            <span class="simple_tip">FUNCTION</span>
                        </div>
                        <div class="container active" v-else>
                            <img src="@/assets/image/icon/feature1.png" class="img2" />
                            <div>
                                <p class="content_tip">功能实用</p>
                                <p class="describe">专注医药工业企业客户需求，设计更贴合实际工作的功能模块，为企业提供专业、实用的销售管理SAAS系统。</p>
                            </div>
                        </div>
                    </div>
                    <div class="list_item" @mouseenter="enterfeature2" @mouseleave="outfeature2">
                        <div class="container" v-if="!featureActive2">
                            <img src="@/assets/image/icon/feature2_2.png" class="img2" />
                            <p class="space"></p>
                            <p class="serial">02</p>
                            <p class="simple">使用便捷</p>
                            <span class="simple_tip">CONVENIENT</span>
                        </div>
                        <div class="container active" v-else>
                            <img src="@/assets/image/icon/feature2.png" class="img2" />
                            <div>
                                <p class="content_tip">使用便捷</p>
                                <span class="describe">系统部署于云服务器，安全高效，提供电脑端、安卓APP、苹果APP、微信小程序4种登陆方式，实现移动办公，便捷高效。</span>
                            </div>
                        </div>
                    </div>
                    <div class="list_item" @mouseenter="enterfeature3" @mouseleave="outfeature3">
                        <div class="container" v-if="!featureActive3">
                            <img src="@/assets/image/icon/feature3_2.png" class="img2" />
                            <p class="space"></p>
                            <p class="serial">03</p>
                            <p class="simple">数据分析</p>
                            <span class="simple_tip">ANALYSIS</span>
                        </div>
                        <div class="container active" v-else>
                            <img src="@/assets/image/icon/feature3.png" class="img2" />
                            <div>
                                <p class="content_tip">数据分析</p>
                                <span class="describe">多维度统计、智能图表展示，实现数据可视化，全面展示公司销售数据，助力精准解读、分析销售数据，及时制定和调整市场作战策略。</span>
                            </div>
                        </div>
                    </div>
                    <div class="list_item" @mouseenter="enterfeature4" @mouseleave="outfeature4">
                        <div class="container" v-if="!featureActive4">
                            <img src="@/assets/image/icon/feature4_2.png" class="img2" />
                            <p class="space"></p>
                            <p class="serial">04</p>
                            <p class="simple">数字化管理</p>
                            <span class="simple_tip">MANAGEMENT</span>
                        </div>
                        <div class="container active" v-else>
                            <img src="@/assets/image/icon/feature4.png" class="img2" />
                            <div>
                                <p class="content_tip">数字化管理</p>
                                <span class="describe">实现全流程数字化管理，建立全流程监管体系，解决流程不规范、流程监管难、制度执行不到位、工作效率低等问题。</span>
                            </div>
                        </div>
                    </div>
                    <div class="list_item" @mouseenter="enterfeature5" @mouseleave="outfeature5">
                        <div class="container" v-if="!featureActive5">
                            <img src="@/assets/image/icon/feature5_2.png" class="img2" />
                            <p class="space"></p>
                            <p class="serial">05</p>
                            <p class="simple">数据安全</p>
                            <span class="simple_tip">SAFETY</span>
                        </div>
                        <div class="container active" v-else>
                            <img src="@/assets/image/icon/feature5.png" class="img2" />
                            <div>
                                <p class="content_tip">数据安全</p>
                                <span class="describe">提供阿里云、腾讯云、华为云数据存储方案，每个客户独享专属服务器，数据安全保密。支持本地和云服务器备份、恢复功能，双重保障数据安全。</span>
                            </div>
                        </div>
                    </div>
                    <div class="list_item" @mouseenter="enterfeature6" @mouseleave="outfeature6">
                        <div class="container" v-if="!featureActive6">
                            <img src="@/assets/image/icon/feature6_2.png" class="img2" />
                            <p class="space"></p>
                            <p class="serial">06</p>
                            <p class="simple">定制服务</p>
                            <span class="simple_tip">CUSTOM</span>
                        </div>
                        <div class="container active" v-else>
                            <img src="@/assets/image/icon/feature6.png" class="img2" />
                            <div>
                                <p class="content_tip">定制服务</p>
                                <span class="describe">为满足客户个性化需求，提供页面定制、功能定制、数据定制、业务逻辑定制，使系统高度贴合实际业务，让客户拥有专属的销售管理SAAS系统。</span>
                            </div>
                        </div>
                    </div>
                    <img class="img_bg" src="@/assets/image/YUNGE_bg.png" />
                </div>
            </div>

            <div class="software_product">
                <div class="features_title">软件产品</div>
                <div class="features_subtitle" style="margin-bottom: 45px">
                    <span class="subtitle" style="background: #f5f5f5">SOFTWARE PRODUCT</span>
                </div>
                <div class="product_tip">
                    <!-- <p class="product_title">云格数字医药工业企业销售管理系统共有3个版本</p> -->
                    <p class="product_title">药品销售管理系统、医疗器械销售管理系统</p>
                    <!-- <p class="product_title">满足不同企业</p> -->
                </div>
                <div class="list">
                    <img src="@/assets/image/software_product.png" class="product_img" @click="jumpProduct(0)" />
                    <img src="@/assets/image/software_product2.png" class="product_img" @click="jumpProduct(1)" />
                </div>
            </div>

            <div class="product_introduce">
                <div class="features_title">产品介绍</div>
                <div class="features_subtitle" style="margin-bottom: 45px">
                    <span class="subtitle">PRODUCT INTRODUCE</span>
                </div>
                <div class="type_list_wrap">
                    <div class="type_list">
                        <div class="type_item" @click="enterIntroduce1" :class="{ product_active: introduceActive1 }">
                            <img src="@/assets/image/icon/system_overview.png" v-if="!introduceActive1" />
                            <img src="@/assets/image/icon/system_overview_active.png" v-else />
                            <span>产品介绍</span>
                        </div>
                        <div class="type_item" @click="enterIntroduce2" :class="{ product_active: introduceActive2 }">
                            <img src="@/assets/image/icon/network.png" v-if="!introduceActive2" />
                            <img src="@/assets/image/icon/network_active.png" v-else />
                            <span>渠道网络</span>
                        </div>
                        <div class="type_item" @click="enterIntroduce3" :class="{ product_active: introduceActive3 }">
                            <img src="@/assets/image/icon/sales_process.png" v-if="!introduceActive3" />
                            <img src="@/assets/image/icon/sales_process_active.png" v-else />
                            <span>销售流程</span>
                        </div>
                        <div class="type_item" @click="enterIntroduce4" :class="{ product_active: introduceActive4 }">
                            <img src="@/assets/image/icon/sales_records.png" v-if="!introduceActive4" />
                            <img src="@/assets/image/icon/sales_records_active.png" v-else />
                            <span>销售档案</span>
                        </div>
                        <div class="type_item" @click="enterIntroduce5" :class="{ product_active: introduceActive5 }">
                            <img src="@/assets/image/icon/plan.png" v-if="!introduceActive5" />
                            <img src="@/assets/image/icon/plan_active.png" v-else />
                            <span>销售计划</span>
                        </div>
                        <img src="@/assets/image/spacing.png" class="spacing" />
                        <p class="more_fun" @click="jump('药品销售管理系统')">
                            <span class="text">更多功能</span>
                        </p>
                    </div>
                </div>

                <div class="content_right">
                    <div v-for="(item, index) in videoList" :key="index">
                        <video width="900" height="500" controls preload="none" :poster="item.poster" v-if="showIndex == index">
                            <source :src="item.src" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>

            <div class="related_services">
                <div class="features_title">相关服务</div>
                <div class="features_subtitle" style="margin-bottom: 45px">
                    <span class="subtitle" style="background: #f5f5f5">RELATED SERVICES</span>
                </div>
                <div class="services_container">
                    <img src="@/assets/image/mac.png" />
                    <div class="list_wrap">
                        <div class="list_item" @click="jump('软件实施服务')" @mouseenter="enterService1" @mouseleave="leaveService1" :class="{ services_active: servicesActive1 }">
                            <div>
                                <div style="display: inline-block; margin-right: 28px">
                                    <img class="img_left" src="@/assets/image/icon/software_implementation.png" v-if="!servicesActive1" />
                                    <img class="img_left" src="@/assets/image/icon/software_implementation_2.png" v-else />
                                </div>
                                <div style="display: inline-block">
                                    <p class="services_title">软件实施服务</p>
                                    <p class="services_title2" :style="{ color: servicesActive1 ? '#fff' : '#999' }">Implementation</p>
                                </div>
                            </div>
                            <img class="jump" src="@/assets/image/icon/jump.png" v-if="!servicesActive1" />
                            <img class="jump" src="@/assets/image/icon/jump2.png" v-else />
                        </div>
                        <div class="list_item" @click="jump('系统运维服务')" @mouseenter="enterService2" @mouseleave="leaveService2" :class="{ services_active2: servicesActive2 }">
                            <div>
                                <div style="display: inline-block; margin-right: 28px">
                                    <img class="img_left" src="@/assets/image/icon/system_operational.png" v-if="!servicesActive2" />
                                    <img class="img_left" src="@/assets/image/icon/system_operational_2.png" v-else />
                                </div>
                                <div style="display: inline-block">
                                    <p class="services_title">系统运维服务</p>
                                    <p class="services_title2" :style="{ color: servicesActive2 ? '#fff' : '#999' }">Operations</p>
                                </div>
                            </div>
                            <img class="jump" src="@/assets/image/icon/jump.png" v-if="!servicesActive2" />
                            <img class="jump" src="@/assets/image/icon/jump2.png" v-else />
                        </div>
                        <div class="list_item" @click="jump('定制开发服务')" @mouseenter="enterService3" @mouseleave="leaveService3" :class="{ services_active3: servicesActive3 }">
                            <div>
                                <div style="display: inline-block; margin-right: 28px">
                                    <img class="img_left" src="@/assets/image/icon/custom_development.png" v-if="!servicesActive3" />
                                    <img class="img_left" src="@/assets/image/icon/custom_development_2.png" v-else />
                                </div>
                                <div style="display: inline-block">
                                    <p class="services_title">定制开发服务</p>
                                    <p class="services_title2" :style="{ color: servicesActive3 ? '#fff' : '#999' }">Customization</p>
                                </div>
                            </div>
                            <img class="jump" src="@/assets/image/icon/jump.png" v-if="!servicesActive3" />
                            <img class="jump" src="@/assets/image/icon/jump2.png" v-else />
                        </div>
                    </div>
                </div>
            </div>

            <div class="qualification">
                <div class="features_title" style="color: #fff; padding-top: 100px">企业资质</div>
                <div class="features_sub">
                    <p class="space"></p>
                    <p class="qualificate_subtitle">QUALIFICATION</p>
                    <p class="space"></p>
                </div>
                <div class="recommendPage">
                    <div class="modular" style="width: 1000px">
                        <swiper :options="swiperOption" ref="mySwiper">
                            <swiper-slide v-for="(x, i) in urls" :key="i">
                                <el-image style="width: 120px; height: 160px" :src="x" :preview-src-list="urls"> </el-image>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                            <div class="swiper-button-prev" slot="button-prev"></div>
                            <div class="swiper-button-next" slot="button-next"></div>
                        </swiper>
                    </div>
                </div>
            </div>

            <div class="industry_infomation">
                <div class="features_title">行业资讯</div>
                <div class="features_subtitle" style="margin-bottom: 45px">
                    <span class="subtitle" style="background: #fff">INDUSTRY INFOMATION</span>
                </div>
                <div class="infomation_wrap">
                    <div>
                        <div :class="[{ infomation_active: infomationActive1 }, 'list_item']" @click="toMore(informationList[0].id, informationList[0].type)" @mouseenter="enterInfo1" @mouseleave="leaveInfo1">
                            <p class="info_title">{{ informationList.length && informationList[0].title }}</p>
                            <p class="info_title2">软件 | {{ informationList.length && informationList[0].infoDate }}</p>
                            <p v-html="informationList[0].content" :class="[{ text_overflow: !infomationActive1 }, 'info_title3']"></p>
                            <div class="jump_detail" v-if="infomationActive1">
                                <span>查看详情</span>
                                <img src="@/assets/image/icon/jump_detail.png" />
                            </div>
                        </div>
                        <div :class="[{ infomation_active: infomationActive2 }, 'list_item']" @click="toMore(informationList[1].id, informationList[1].type)" @mouseenter="enterInfo2" @mouseleave="leaveInfo2">
                            <p class="info_title">{{ informationList.length && informationList[1] && informationList[1].title }}</p>
                            <p class="info_title2">医药 | {{ informationList.length && informationList[1] && informationList[1].infoDate }}</p>
                            <p :class="[{ text_overflow: !infomationActive2 }, 'info_title3']" v-html="informationList.length && informationList[1] && informationList[1].content"></p>
                            <div class="jump_detail" v-if="infomationActive2">
                                <span>查看详情</span>
                                <img src="@/assets/image/icon/jump_detail.png" />
                            </div>
                        </div>
                        <div :class="[{ infomation_active: infomationActive3 }, 'list_item']" @click="toMore(informationList[2].id, informationList[2].type)" @mouseenter="enterInfo3" @mouseleave="leaveInfo3">
                            <p class="info_title">{{ informationList.length && informationList[2] && informationList[2].title }}</p>
                            <p class="info_title2">数字经济 | {{ informationList.length && informationList[2] && informationList[2].infoDate }}</p>
                            <p :class="[{ text_overflow: !infomationActive3 }, 'info_title3']" v-html="informationList.length && informationList[2] && informationList[2].content"></p>
                            <div class="jump_detail" v-if="infomationActive3">
                                <span>查看详情</span>
                                <img src="@/assets/image/icon/jump_detail.png" />
                            </div>
                        </div>
                    </div>
                    <div class="img_right">
                        <swiper :options="swiperOptionOne" class="img_right">
                            <swiper-slide v-for="(x, i) in imageList" :key="i">
                                <el-image :src="x"> </el-image>
                            </swiper-slide>
                        </swiper>
                        <div class="underlying_bg"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
// npm install vue-awesome-swiper@3 --save-dev 多图片轮播
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { getSimpleText } from '../../util/util'

import { getPhoneCode1, applyRegister, getThreeInfo, isRegister } from '@/request/api.js' // 导入api接口
// 电话手机号码自定义校验
var phone = (rule, value, callback) => {
    var reg = /^1([3-9][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
    var regExp = new RegExp(reg)
    if (value && !regExp.test(value)) return callback(new Error('请输入正确格式的手机号码'))
    callback()
}
export default {
    components: { swiper, swiperSlide },
    data() {
        return {
            formData: { name: '', phone: '', code: '' },
            advantageList: [
                { img: require('../../assets/image/gongneng.png'), introduce: '功能实用', describe: '多年深研医药工业企业销售流程，打造更加符合日常销售的应用功能，并通过不断的提升改造，致力于为医药工业企业拥有自己的数字化销售管理系统提供机遇机会。' },
                { img: require('../../assets/image/yunduanfuwu.png'), introduce: '云端服务', describe: '系统搭载于云服务器上，不同于传统的物理服务器，云服务器更高效安全可靠，处理能力可弹性伸缩，日常维护成本更低。' },
                { img: require('../../assets/image/shujufenxi.png'), introduce: '数据分析', describe: '智能分析销售数据，实现数据可视化，系统全面的了解公司销售情况，助力企业发展。' },
                { img: require('../../assets/image/bianjie.png'), introduce: '便捷无忧', describe: '用户可以通过网络随时随地访问系统，让业务更加便捷。' },
                { img: require('../../assets/image/cunchu.png'), introduce: '数据存储', describe: '提供阿里云、腾讯云、华为云等知名服务商数据存储方案，全面保障数据安全。' },
                { img: require('../../assets/image/anquan.png'), introduce: '数据安全', describe: '系统设置本地和云服务器的数据备份及恢复功能，双重保障用户数据安全。' }
            ],
            intendList: [
                { img: require('../../assets/image/product_1.png'), introduce: '药品销售管理系统', title: 'Medicine', title2: 'Management', title3: 'System' },
                // { img: require('../../assets/image/product_2.png'), introduce: '中药饮片销售管理系统', title: 'Traditional', title2: 'Chinese', title3: 'Medicine' },
                { img: require('../../assets/image/product_3.png'), introduce: '医疗器械销售管理系统', title: 'Medicine Apparatus', title2: 'And', title3: 'Instruments' }
            ],
            serviceList: [
                { img: require('../../assets/image/icon/software_implementation_2.png'), introduce: '软件实施服务', title: 'Implementation' },
                { img: require('../../assets/image/icon/system_operational_2.png'), introduce: '系统运维服务', title: 'Operations' },
                { img: require('../../assets/image/icon/custom_development_2.png'), introduce: '定制开发服务', title: 'Customization' }
            ],
            informationList: [
                { introduce: '互联网科技峰会召开', describe: '这里显示文章第一句话这里显示文章第一句话这里显示文章第一句话这里显示......' },
                { introduce: '互联网科技峰会召开', describe: '这里显示文章第一句话这里显示文章第一句话这里显示文章第一句话这里显示......' },
                { introduce: '互联网科技峰会召开', describe: '这里显示文章第一句话这里显示文章第一句话这里显示文章第一句话这里显示......' }
            ],
            imageList: ['https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/hangyezixun2.png', 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/hangyezixun1.png'],
            urls: [
                require('../../assets/image/rzimg/rz1.jpg'),
                require('../../assets/image/rzimg/rz2.jpg'),
                require('../../assets/image/rzimg/rz3.png'),
                require('../../assets/image/rzimg/rz4.jpg'),
                require('../../assets/image/rzimg/rz5.jpg'),
                require('../../assets/image/rzimg/rz6.jpg'),
                require('../../assets/image/rzimg/rz7.jpg'),
                require('../../assets/image/rzimg/rz8.jpg'),
                require('../../assets/image/rzimg/rz9.jpg'),
                require('../../assets/image/rzimg/rz10.jpg'),
                require('../../assets/image/rzimg/rz11.jpg'),
                require('../../assets/image/rzimg/rz12.jpg'),
                require('../../assets/image/rzimg/rz13.jpg'),
                require('../../assets/image/rzimg/rz14.jpg'),
                require('../../assets/image/rzimg/rz15.jpg'),
                require('../../assets/image/rzimg/rz16.jpg'),
                require('../../assets/image/rzimg/rz17.jpg'),
                require('../../assets/image/rzimg/rz18.jpg'),
                require('../../assets/image/rzimg/rz19.jpg'),
                require('../../assets/image/rzimg/rz20.jpg'),
                require('../../assets/image/rzimg/rz21.jpg'),
                require('../../assets/image/rzimg/rz22.jpg'),
                require('../../assets/image/rzimg/rz23.jpg'),
                require('../../assets/image/rzimg/rz24.jpg'),
                require('../../assets/image/rzimg/rz25.jpeg'),
                require('../../assets/image/rzimg/rz26.jpeg'),
                require('../../assets/image/rzimg/rz27.jpeg'),
                require('../../assets/image/rzimg/rz28.jpeg'),
                require('../../assets/image/rzimg/rz29.jpeg')
            ],
            carousel: ['https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/beijing.png', 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/beijing2.png'],
            showIndex: '0',
            videoList: [
                {
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/yunge_sample_piece.m4v',
                    poster: require('@/assets/image/chanpinjieshao1.png')
                },
                {
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/qudaowangluo.mp4',
                    poster: require('@/assets/image/chanpinjieshao2.png')
                },
                {
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/xiaoshouliucheng.mp4',
                    poster: require('@/assets/image/chanpinjieshao3.png')
                },
                {
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/xiaoshoudangan.mp4',
                    poster: require('@/assets/image/chanpinjieshao4.png')
                },
                {
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/xiaoshoujihua.mp4',
                    poster: require('@/assets/image/chanpinjieshao5.png')
                }
            ],
            rules: {
                name: [{ required: true, message: '请输入您的名字', trigger: 'change' }],
                phone: [
                    { required: true, message: '请输入您的手机号', trigger: 'change' },
                    { validator: phone, trigger: 'change' }
                ]
            },
            rules2: {
                name: [{ required: true, message: '请输入您的名字', trigger: 'change' }],
                phone: [
                    { required: true, message: '请输入您的手机号', trigger: 'change' },
                    { validator: phone, trigger: 'change' }
                ],
                code: [{ required: true, message: '请输入验证码', trigger: 'change' }]
            },
            show: true,
            count: '',
            timer: null,
            swiperOption: {
                slidesPerView: 6,
                // loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // 设置点击箭头
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionOne: {
                slidesPerView: 1,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
            },
            featureActive1: false,
            featureActive2: false,
            featureActive3: false,
            featureActive4: false,
            featureActive5: false,
            featureActive6: false,
            productActive: false,
            servicesActive1: false,
            servicesActive2: false,
            servicesActive3: false,
            infomationActive1: false,
            infomationActive2: false,
            infomationActive3: false,
            introduceActive1: true,
            introduceActive2: false,
            introduceActive3: false,
            introduceActive4: false,
            introduceActive5: false
        }
    },
    created() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        getThreeInfo().then((res) => {
            if (res.code == '200') {
                // src="https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/fzygsz/84811612081634075.jpg"
                this.informationList = res.data
                this.informationList.forEach((x, i) => {
                    x.content = getSimpleText(x.content)
                    x.image = this.imageList[i]
                })
            }
        })
    },
    mounted() {},
    methods: {
        toMore(id, type) {
            this.$router.push({ path: `/index/message/detail?active=${type}&id=${id}` })
        },
        obtain(val) {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    isRegister({ phone: this.formData.phone, name: this.formData.name }).then((result) => {
                        if (result == 0) {
                            getPhoneCode1({ phone: val, template: '7' }).then((res) => {
                                if (res.code == '200') {
                                    this.$message.success(res.msg)
                                    // 点击获取验证码
                                    const TIME_COUNT = 60 // 倒计时60秒
                                    if (!this.timer) {
                                        this.count = TIME_COUNT
                                        this.show = false
                                        this.timer = setInterval(() => {
                                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                                this.count--
                                            } else {
                                                this.show = true
                                                clearInterval(this.timer)
                                                this.timer = null
                                            }
                                        }, 1000)
                                    }
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        } else if (result == 1) {
                            this.$message.warning('当前名称已进行申请！')
                        } else if (result == 2) {
                            this.$message.warning('当前手机号已进行申请！')
                        } else if (result == 3) {
                            this.$message.warning('当前手机号格式错误！')
                        }
                    })
                }
            })
        },
        submitForm() {
            this.rules = JSON.parse(JSON.stringify(this.rules2))
            this.$refs.formData.validate((valid) => (valid ? applyRegister(this.formData).then((res) => (res.code == '200' ? this.$message.success(res.msg) : this.$message.warning(res.msg))) : this.$message.warning('请检查信息与格式是否填写完整')))
        },
        jump(key) {
            switch (key) {
                case '软件实施服务':
                    this.$router.push({ path: '/index/service?active=0' })
                    break
                case '系统运维服务':
                    this.$router.push({ path: '/index/service?active=1' })
                    break
                case '定制开发服务':
                    this.$router.push({ path: '/index/service?active=2' })
                    break
                case '药品销售管理系统':
                    this.$router.push({ path: '/index/product?active=0' })
                    break
                case '中药饮片销售管理系统':
                    this.$router.push({ path: '/index/decoctionPieces?active=0' })
                    break
                case '医疗器械销售管理系统':
                    this.$router.push({ path: '/index/instruments?active=0' })
                    break
                default:
                    break
            }
        },
        // 跳转软件产品
        jumpProduct(index) {
            if (index == 1) {
                this.$router.push({ path: '/index/instruments?active=0' })
            } else {
                this.$router.push({ path: '/index/product?active=0' })
            }
        },
        enterfeature1() {
            this.featureActive1 = true
        },
        outfeature1() {
            this.featureActive1 = false
        },
        enterfeature2() {
            this.featureActive2 = true
        },
        outfeature2() {
            this.featureActive2 = false
        },
        enterfeature3() {
            this.featureActive3 = true
        },
        outfeature3() {
            this.featureActive3 = false
        },
        enterfeature4() {
            this.featureActive4 = true
        },
        outfeature4() {
            this.featureActive4 = false
        },
        enterfeature5() {
            this.featureActive5 = true
        },
        outfeature5() {
            this.featureActive5 = false
        },
        enterfeature6() {
            this.featureActive6 = true
        },
        outfeature6() {
            this.featureActive6 = false
        },
        enterService1() {
            this.servicesActive1 = true
        },
        leaveService1() {
            this.servicesActive1 = false
        },
        enterService2() {
            this.servicesActive2 = true
        },
        leaveService2() {
            this.servicesActive2 = false
        },
        enterService3() {
            this.servicesActive3 = true
        },
        leaveService3() {
            this.servicesActive3 = false
        },
        enterInfo1() {
            this.infomationActive1 = true
        },
        leaveInfo1() {
            this.infomationActive1 = false
        },
        enterInfo2() {
            this.infomationActive2 = true
        },
        leaveInfo2() {
            this.infomationActive2 = false
        },
        enterInfo3() {
            this.infomationActive3 = true
        },
        leaveInfo3() {
            this.infomationActive3 = false
        },
        enterIntroduce1() {
            this.introduceActive1 = true
            this.introduceActive2 = false
            this.introduceActive3 = false
            this.introduceActive4 = false
            this.introduceActive5 = false
            this.showIndex = 0
        },
        enterIntroduce2() {
            this.introduceActive1 = false
            this.introduceActive2 = true
            this.introduceActive3 = false
            this.introduceActive4 = false
            this.introduceActive5 = false
            this.showIndex = 1
        },
        enterIntroduce3() {
            this.introduceActive1 = false
            this.introduceActive2 = false
            this.introduceActive3 = true
            this.introduceActive4 = false
            this.introduceActive5 = false
            this.showIndex = 2
        },
        enterIntroduce4() {
            this.introduceActive1 = false
            this.introduceActive2 = false
            this.introduceActive3 = false
            this.introduceActive4 = true
            this.introduceActive5 = false
            this.showIndex = 3
        },
        enterIntroduce5() {
            this.introduceActive1 = false
            this.introduceActive2 = false
            this.introduceActive3 = false
            this.introduceActive4 = false
            this.introduceActive5 = true
            this.showIndex = 4
        }
    },
    watch: {}
}
</script>

<style lang="less" scoped>
.beijing {
    position: relative;
    width: 100%;
    height: 600px;
    .swiper-wrap {
        .swiper-slide {
            width: 100% !important;
            .el-image {
                width: 100% !important;
                height: 600px !important;
            }
        }
    }
    .loginBox {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 19.5%;
        width: 300px;
        height: 310px;
        padding: 30px 27px;
        background-color: #fff;
        z-index: 11;
        border-radius: 10px;
        .herderBox {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2284f1;
            text-align: center;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #ccc;
        }
        .code {
            display: flex;
            justify-content: space-between;
            .el-input {
                width: 55%;
            }
            .el-button {
                margin-left: 5%;
                width: 120px;
            }
        }
        .submitForm {
            cursor: pointer;
            margin: 0 auto;
            width: 300px;
            height: 40px;
            background-color: #1158e8;
            color: #fff;
            font-weight: 500;
            font-size: 18px;
            line-height: 40px;
            text-align: center;
            border-radius: 5px;
        }
        .toSystem {
            text-align: right;
            a {
                color: #1158e8;
                cursor: pointer;
            }
        }
    }
}
.recommendPage {
    margin: 20px 0;
    .swiper-container {
        box-sizing: border-box;
        background-size: 100% 100%;
        padding: 20px 0;
        position: relative;
        height: 200px;
        .swiper-slide {
            width: 100%;
            line-height: 200px;
            color: #000;
            font-size: 16px;
            text-align: center;
        }
    }
}
.features_title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #1158e8;
    margin-bottom: 19px;
}
.features_subtitle {
    position: relative;
    border-bottom: 1px solid #cccccc;
    width: 50%;
    margin: 0 auto 35px;
    .subtitle {
        position: absolute;
        left: 50%;
        top: -10px;
        background: #fff;
        transform: translateX(-50%);
        padding: 0 24px;
        color: #999999;
        font-size: 20px;
    }
}
.product_features {
    margin-top: 106px;
    .list {
        display: flex;
        justify-content: center;
        position: relative;
        background: #fff;
        margin: 100px 0 140px;
        .list_item {
            margin: 0 23px;
            z-index: 1;
            img {
                width: 68px;
                height: 68px;
            }
        }
        .head {
            text-align: center;
            margin-bottom: 17px;
        }
        .container {
            width: 160px;
            // width: 160px;
            height: 390px;
            text-align: center;
            border-radius: 10px;
            border: 3px solid #e6e6e6;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .img2 {
            margin: 0 auto 20px;
        }
        .content_tip {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 19px;
        }
        .describe {
            font-size: 14px;
            padding: 0 22px;
            text-align: center;
            display: inline-block;
            margin: auto;
            font-weight: 400;
            letter-spacing: 1.2px;
            font-family: Microsoft YaHei;
            // text-align: justify;
            text-align: justify;
            // text-align-last: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .active {
            background: #1158e8;
            border: none;
            color: #fff;
            border: 3px solid #1158e8;
        }
        .space {
            width: 26px;
            height: 4px;
            background: #1158e8;
            margin: 0 auto;
        }
        .serial {
            font-size: 30px;
            font-weight: bold;
            margin: 33px auto 24px;
        }
        .simple {
            font-size: 20px;
            margin-bottom: 9px;
        }
        .simple_tip {
            font-size: 12px;
            color: #999999;
        }
        .img_bg {
            position: absolute;
            bottom: -142px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.software_product {
    background: #f5f5f5;
    padding: 100px 0 117px;
    .product_tip {
        text-align: center;
        margin-bottom: 59px;
        .product_title {
            font-size: 20px;
            font-weight: 400;
            color: #999999;
            margin-bottom: 10px;
        }
    }
    .list {
        text-align: center;
        .product_img {
            margin: 0 10px;
            border: 3px solid #fff;
            border-radius: 20px;
            cursor: pointer;
        }
        .product_img:hover {
            border: 3px solid #1158e8;
        }
    }
}
.product_introduce {
    padding: 100px 0 117px;
    .type_list_wrap {
        margin: auto;
    }
    .type_list {
        display: flex;
        width: 1245px;
        margin: auto;
        .type_item {
            cursor: pointer;
            width: 180px;
            height: 60px;
            padding: 20px 0;
            // line-height: 60px;
            background: #f5f5f5;
            color: #000;
            // font-size: 20px;
            text-align: center;
            margin-right: 23px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-content: center;
            box-sizing: border-box;
            img {
                margin-right: 9px;
                width: 20px;
                height: 20px;
            }
        }
        .product_active {
            color: #fff;
            background: #1158e8;
        }
        .spacing {
            position: relative;
            top: -10px;
        }
        .more_fun {
            font-size: 20px;
            font-weight: bold;
            font-style: italic;
            color: #1158e8;
            position: relative;
            width: 205px;
            position: relative;
            top: -10px;
            .text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }
        }
    }
    .content_right {
        text-align: center;
        margin-top: 20px;
        video {
            // object-fit: cover;
            object-fit: contain;
        }
        video:focus {
            outline: none;
        }
    }
}

.related_services {
    padding: 100px 0 95px;
    background: #f5f5f5;
    .services_container {
        display: flex;
        justify-content: center;
        .list_wrap {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .list_item {
                display: flex;
                justify-content: space-between;
                width: 380px;
                height: 98px;
                border-radius: 0px 36px 0px 36px;
                padding: 24px 30px;
                box-sizing: border-box;
                position: relative;
                background: #fff;
                .services_title {
                    font-size: 24px;
                    font-style: italic;
                }
                .services_title2 {
                    font-size: 14px;
                    font-style: italic;
                    color: #999999;
                }
                .img_left {
                    width: 42px;
                    height: 42px;
                    margin-right: 28px;
                    margin: auto;
                }
                .jump {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .services_active {
                background-image: url('../../assets/image/services_bg1.png');
                background-size: 100% 100%;
                color: #fff;
            }
            .services_active2 {
                background-image: url('../../assets/image/services_bg2.png');
                background-size: 100% 100%;
                color: #fff;
            }
            .services_active3 {
                background-image: url('../../assets/image/services_bg3.png');
                background-size: 100% 100%;
                color: #fff;
            }
            .list_item:hover {
                cursor: pointer;
            }
        }
    }
}
.qualification {
    background: url('../../assets/image/qualification_bg.png');
    height: 500px;
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        background-image: url('../../assets/image/icon/aysq7-b1olp.svg');
        left: -3px;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        background-image: url('../../assets/image/icon/aahi9-vpbg5.svg');
        right: -3px;
    }
    .features_title {
        margin-bottom: 0;
    }
    .features_sub {
        display: flex;
        justify-content: center;
        .space {
            border-bottom: 1px solid #fff;
            width: 200px;
        }
        .qualificate_subtitle {
            color: #fff;
            margin: 0 49px;
            position: relative;
            top: 12px;
            font-size: 20px;
        }
    }
}
.industry_infomation {
    padding: 100px 0 120px;
    .infomation_wrap {
        display: flex;
        justify-content: center;
        height: 450px;
        .list_item {
            width: 480px;
            background: #f5f5f5;
            border-radius: 16px;
            padding: 17px 20px;
            box-sizing: border-box;
            margin-bottom: 12px;
            .info_title {
                font-size: 18px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 10px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .info_title2 {
                font-size: 14px;
                color: #000000;
                margin-bottom: 18px;
            }
            .info_title3 {
                font-size: 14px;
                color: #999999;
            }
            .text_overflow {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .jump_detail {
                float: right;
                margin-top: 19px;
                font-size: 14px;
                color: #1158e8;
                img {
                    width: 6px;
                    height: 6px;
                    margin-left: 10px;
                }
            }
        }
        .list_item:hover {
            cursor: pointer;
        }
        .img_right {
            width: 680px;
            height: 420px;
            margin-left: 20px;
            position: relative;
            .el-image {
                width: 680px;
                height: 420px;
            }
            .underlying_bg {
                position: absolute;
                top: -20px;
                right: -40px;
                width: 680px;
                height: 400px;
                background: #f5f5f5;
                border-radius: 16px;
            }
        }
        .infomation_active {
            height: 180px;
            border: 3px solid #1158e8;
            border-radius: 16px;
            .info_title3 {
                font-size: 14px;
                color: #999999;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .list:hover {
            cursor: pointer;
        }
    }
}
</style>
